import * as React from 'react'
import styled from 'styled-components'

import * as behaviours from '../../behaviours'
import * as glyphs from '../../icons/glyphs'
import * as notification from '../../styles/notification'

const baseGlyphs: {
  [s in notification.NotificationType]: glyphs.IconElement
} = {
  alert: glyphs.fill.Alert,
  error: glyphs.fill.TimesCircle,
  info: glyphs.fill.Info,
  success: glyphs.fill.Success,
}

export type BaseProps = {
  children: React.ReactNode
  className?: string
  customIcon?: glyphs.IconElement
  type: notification.NotificationType
}

type AnimationProps = {
  onTransitionEnd?: React.HTMLAttributes<HTMLDivElement>['onTransitionEnd']
  step?: behaviours.UseTransition['step']
}

function BaseComponent({
  className,
  children,
  customIcon: CustomIcon,
  onTransitionEnd,
  step,
  type,
}: BaseProps & AnimationProps): React.ReactElement<BaseProps> {
  const Glyph = baseGlyphs[type]

  return (
    <notification.BaseNotification className={className} onTransitionEnd={onTransitionEnd} step={step} type={type}>
      {CustomIcon ? <CustomIcon size={16} /> : <Glyph size={18} />}
      {children}
    </notification.BaseNotification>
  )
}

export const Base = styled(BaseComponent)<BaseProps>``
Base.displayName = 'Base'
